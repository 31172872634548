'use client';

import { faAngleLeft, faAngleRight, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@pickleballinc/ui/components/ui/tabs';
import { UnstyledButton } from '@pickleballinc/ui/components/ui/unstyled-button';
import { clsx } from 'clsx';
import { useMemo, useState } from 'react';
import { CalendarProps, DateRange, Range } from 'react-date-range';

import { CircleWidget } from '@/components/CircleWidget';
import { useTournaments } from '@/tournament/lib/context';

interface TabItemProps {
	children: React.ReactNode;
	value: string;
	active?: boolean;
}

const TabItem = ({ children, value, active }: TabItemProps) => {
	const classes = clsx('flex-1 cursor-pointer rounded-full border py-1.5', {
		'bg-white border-gray-300 bg-white': active,
		'border-gray-100 hover:bg-gray-300 hover:border-gray-300': !active
	});
	return (
		<TabsTrigger value={value} asChild className={classes}>
			<div className="text-lg font-semibold">{children}</div>
		</TabsTrigger>
	);
};

interface AddDatesContentProps {
	dateNoMonths?: number;
}

export const AddDatesContent = ({ dateNoMonths = 2 }: AddDatesContentProps) => {
	const { dates, setDates, pastEvents, setPastEvents } = useTournaments();

	const [activeTab, setActiveTab] = useState('dates');
	const ranges: Range = useMemo(() => {
		const startDate = dates?.from ? new Date(dates?.from) : new Date();
		const endDate = dates?.to ? new Date(dates?.to) : new Date();

		return {
			startDate,
			endDate,
			color: '#f7f7f7',
			key: 'tournaments'
		};
	}, [dates]);

	const derivedActiveTab = pastEvents ? 'past-events' : activeTab;

	const renderMonthAndYear = (_focusedDate: any, changeShownDate: any, props: CalendarProps) => {
		const { showMonthArrow } = props;

		return (
			<div className="absolute top-4 flex w-full justify-between px-14">
				{showMonthArrow ? (
					<UnstyledButton type="button" onClick={() => changeShownDate(-1, 'monthOffset')}>
						<FontAwesomeIcon size="lg" icon={faAngleLeft} />
					</UnstyledButton>
				) : null}
				{showMonthArrow ? (
					<UnstyledButton type="button" onClick={() => changeShownDate(+1, 'monthOffset')}>
						<FontAwesomeIcon size="lg" icon={faAngleRight} />
					</UnstyledButton>
				) : null}
			</div>
		);
	};

	const toggleClasses = clsx(
		'ring-offset-background focus-visible:ring-ring data-[state=active]:bg-background data-[state=active]:text-foreground inline-flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap rounded-full border border-gray-100 px-3 py-1.5 text-lg text-sm font-medium font-semibold transition-all hover:border-gray-300 hover:bg-gray-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:shadow',
		{ 'bg-white border-gray-300': pastEvents }
	);

	return (
		<div className={`${derivedActiveTab === 'past-events' ? '!pb-6' : ''}`}>
			<Tabs
				value={derivedActiveTab}
				onValueChange={(value) => {
					setActiveTab(value);
					setPastEvents(false);
					false;
				}}
			>
				<TabsList className="mx-auto flex h-auto w-80 items-center gap-x-2 rounded-full bg-gray-100 text-base">
					<TabItem value="dates" active={derivedActiveTab === 'dates'}>
						Dates
					</TabItem>
					<TabItem value="months" active={derivedActiveTab === 'months'}>
						Months
					</TabItem>
					<UnstyledButton
						type="button"
						className={toggleClasses}
						onClick={() => {
							setPastEvents(!pastEvents);
						}}
					>
						Past
						{pastEvents && <FontAwesomeIcon icon={faCheck} className="ml-2" size="sm" />}
					</UnstyledButton>
				</TabsList>
				<TabsContent value="dates" className="pb-2 md:pb-0">
					<div className="relative my-2 md:px-6">
						<DateRange
							onChange={(ranges) => {
								const range = ranges['tournaments'];
								if (range) {
									setDates({ from: range?.startDate?.toString(), to: range?.endDate?.toString(), months: undefined });
								}
							}}
							ranges={[ranges]}
							months={dateNoMonths}
							direction={'horizontal'}
							moveRangeOnFirstSelection={false}
							retainEndDateOnFirstSelection
							preventSnapRefocus
							navigatorRenderer={renderMonthAndYear}
							weekStartsOn={0}
							weekdayDisplayFormat="EEEEEE"
							showDateDisplay={false}
							showPreview={false}
							scroll={{ enabled: false }}
							showMonthAndYearPickers={false}
						/>
					</div>
				</TabsContent>
				<TabsContent value="months" className="pb-6 md:pb-0">
					<div className="flex w-full flex-1 flex-col items-center justify-center pb-0 pt-8">
						<div className="mb-6 text-lg font-semibold">What period?</div>
						<CircleWidget steps={12} radius={145} value={dates?.months || 1} />
						<div className="mt-6 font-normal">
							Starting from{' '}
							{new Intl.DateTimeFormat('en-US', {
								year: 'numeric',
								month: 'long',
								day: 'numeric'
							}).format(new Date())}
						</div>
					</div>
				</TabsContent>
			</Tabs>
		</div>
	);
};
