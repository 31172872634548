'use client';

import React from 'react';

import { useSearchHeader } from '../lib/context';

interface FullSearchFormProps {
	children: React.ReactNode;
	shown?: boolean;
}

export const FullSearchForm = ({ children, shown }: FullSearchFormProps) => {
	const { expanded } = useSearchHeader();
	const transform = `scale(0.41,0.7272727272727273) translateY(-58px)`;

	const isShown = shown || expanded;

	return (
		<div
			className="absolute left-0 top-0 z-10 w-full px-10 text-left md:px-10"
			style={{
				transformOrigin: '50% 0',
				transition: 'transform 250ms cubic-bezier(0.2,0,0,1),opacity 150ms 100ms cubic-bezier(0.2,0,0,1),visibility 0ms 125ms',
				...(!isShown && { transform }),
				opacity: !isShown ? 0 : 1,
				...(!isShown && { pointerEvents: 'none' }),
				visibility: isShown ? 'visible' : 'hidden'
			}}
		>
			{children}
		</div>
	);
};
