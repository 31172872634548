'use client';

import clsx from 'clsx';
import React from 'react';

import { useOutsideClick } from '../../lib/outside-click';
import { useSearchHeader } from '../lib/context';

interface SearchHeaderProps {
	children: React.ReactNode;
	offset?: number;
	height?: number;
}

export const SearchHeader = ({ children, offset, height = 168 }: SearchHeaderProps) => {
	const { scrollTop, expanded, initialExpanded, toggleExpand } = useSearchHeader();

	const ref = useOutsideClick(() => {
		if (expanded) {
			toggleExpand(false);
		}
	});

	const scaleSize = height / 80;
	const headerClasses = clsx('search-header relative h-16 w-full', {
		'after:shadow-border': (!expanded && scrollTop !== 0) || (!expanded && !initialExpanded),
		'after:scale-y-[var(--pb-header-scale-y)] z-[100]': (expanded && scrollTop !== 0) || (expanded && !initialExpanded)
	});

	const headerStyle = {
		top: offset || 0,
		transition: 'height 250ms cubic-bezier(0.2,0,0,1)',
		'--pb-header-scale-y': scaleSize.toString(),
		'--pb-header-height': `${height.toString()}px`,
		willChange: 'height',
		containIntrinsicHeight: expanded && scrollTop === 0 && initialExpanded ? height : 80,
		contain: 'size style layout'
	};

	return (
		<div
			className={`hidden bg-white md:flex ${expanded && scrollTop <= 0 && initialExpanded ? `h-[var(--pb-header-height)] shadow-border` : 'h-20'}`}
			style={headerStyle}
			ref={ref}
		>
			<header className={headerClasses}>
				<div className="relative z-10 flex size-full items-center justify-between px-10 md:px-10 xl:px-20">{children}</div>
			</header>
			{((expanded && scrollTop > 0) || (expanded && !initialExpanded)) && (
				<div
					className="fixed inset-0 z-[99] h-screen bg-black/25"
					onClick={() => {
						toggleExpand(false);
					}}
				/>
			)}
		</div>
	);
};
