'use client';

import { FullSearchForm as FullSearchFormWrapper } from '@pickleballinc/features/src/search-header/components/FullSearchForm';
import React, { useCallback, useState } from 'react';

import { useTournaments } from '../tournament/lib/context';
import { AddDates } from './AddDates';
import { Keyword } from './Keyword';
import { Location } from './Locations';

/* const NavItem = ({ children, href }: { children: React.ReactNode; href: string }) => {
	const pathname = usePathname();

	const classes = clsx('text-sm', {
		'font-normal text-black/70': href !== pathname,
		'font-semibold text-black': href === pathname
	});

	return (
		<li className="list-none">
			<Link href={href} className={classes}>
				{children}
			</Link>
		</li>
	);
}; */

export const FullSearchForm = () => {
	const { getTournaments, setKeyword } = useTournaments();
	const [searchActive, setSearchActive] = useState(false);

	const onFormSubmit = useCallback(() => {
		getTournaments();
	}, [getTournaments]);

	return (
		<FullSearchFormWrapper>
			<form className="m-auto max-w-[850px] pt-4">
				<div
					className="relative h-[66px] rounded-full border border-[#DDDDDD]"
					style={{ boxShadow: '0 3px 12px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.08)' }}
				>
					<div className="flex-[1 1 0%] flex h-full min-w-0 items-center">
						<Keyword
							onSearchActive={(active) => setSearchActive(active)}
							onChange={(keyword) => {
								setKeyword(keyword);
							}}
						/>
						<div className="h-8 border-r border-[#DDDDDD]" style={{ flex: '0 0 0' }} />
						<Location onSearchActive={(active) => setSearchActive(active)} />
						<div className="h-8 border-r border-[#DDDDDD]" style={{ flex: '0 0 0' }} />
						<AddDates
							searchActive={searchActive}
							onSearchActive={(open) => setSearchActive(open)}
							onSubmit={() => {
								onFormSubmit();
							}}
						/>
					</div>
				</div>
			</form>
		</FullSearchFormWrapper>
	);
};
