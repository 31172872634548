export const regions = [
	{
		name: 'Pacific',
		icon: '/pickleball-app/static/images/pacific-icon.svg',
		href: '/search',
		placeId: '',
		place: {
			formatted_address: 'Pacific',
			place_id: '',
			geometry: {
				bounds: {
					northeast: {
						lat: 49.0,
						lng: -115.0
					},
					southwest: {
						lat: 32.0,
						lng: -125
					}
				},
				location: {
					lat: 40.5,
					lng: -120
				},
				viewport: {
					northeast: {
						lat: 49.0,
						lng: -115.0
					},
					southwest: {
						lat: 32.0,
						lng: -125
					}
				}
			}
		}
	},
	{
		name: 'Mountain',
		icon: '/pickleball-app/static/images/mountain-icon.svg',
		href: '/search',
		placeId: '',
		place: {
			formatted_address: 'Mountain',
			place_id: '',
			geometry: {
				bounds: {
					northeast: {
						lat: 49.0,
						lng: -102.0
					},
					southwest: {
						lat: 31.0,
						lng: -115.0
					}
				},
				location: {
					lat: 40.0,
					lng: -108.5
				},
				viewport: {
					northeast: {
						lat: 49.0,
						lng: -102.0
					},
					southwest: {
						lat: 31.0,
						lng: -115.0
					}
				}
			}
		}
	},
	{
		name: 'Central',
		icon: '/pickleball-app/static/images/central-icon.svg',
		href: '/search',
		placeId: '',
		place: {
			formatted_address: 'Central',
			place_id: '',
			geometry: {
				bounds: {
					northeast: {
						lat: 49.0,
						lng: -89
					},
					southwest: {
						lat: 25.0,
						lng: -102
					}
				},
				location: {
					lat: 37.0,
					lng: -95.5
				},
				viewport: {
					northeast: {
						lat: 49.0,
						lng: -89
					},
					southwest: {
						lat: 25.0,
						lng: -102
					}
				}
			}
		}
	},
	{
		name: 'Eastern',
		icon: '/pickleball-app/static/images/eastern-icon.svg',
		href: '/search',
		placeId: '',
		place: {
			formatted_address: 'Eastern',
			place_id: '',
			geometry: {
				bounds: {
					northeast: {
						lat: 49.0,
						lng: -67
					},
					southwest: {
						lat: 24.0,
						lng: -89
					}
				},
				location: {
					lat: 36.5,
					lng: -78
				},
				viewport: {
					northeast: {
						lat: 49.0,
						lng: -67
					},
					southwest: {
						lat: 24.0,
						lng: -89
					}
				}
			}
		}
	},
	{
		name: 'Anywhere',
		icon: '/pickleball-app/static/images/anywhere.svg',
		href: '/search'
	},
	{
		name: 'United States',
		icon: '/pickleball-app/static/images/usa.svg',
		href: '/search',
		placeId: 'ChIJCzYy5IS16lQRQrfeQ5K5Oxw',
		place: {
			formatted_address: 'United States',
			place_id: 'ChIJCzYy5IS16lQRQrfeQ5K5Oxw',
			geometry: {
				bounds: {
					northeast: {
						lat: 72.7087158,
						lng: -66.3193754
					},
					southwest: {
						lat: 15.7760139,
						lng: -173.2992296
					}
				},
				location: {
					lat: 37.09024,
					lng: -95.712891
				},
				viewport: {
					northeast: {
						lat: 72.7087158,
						lng: -66.3193754
					},
					southwest: {
						lat: 15.7760139,
						lng: -173.2992296
					}
				}
			}
		}
	},
	{
		name: 'Canada',
		icon: '/pickleball-app/static/images/canada.svg',
		href: '/search',
		placeId: 'ChIJ2WrMN9MDDUsRpY9Doiq3aJk',
		place: {
			formatted_address: 'Canada',
			place_id: 'ChIJ2WrMN9MDDUsRpY9Doiq3aJk',
			geometry: {
				bounds: {
					northeast: {
						lat: 83.6381,
						lng: -50.9766
					},
					southwest: {
						lat: 41.6765559,
						lng: -141.00187
					}
				},
				location: {
					lat: 56.130366,
					lng: -106.346771
				},
				viewport: {
					northeast: {
						lat: 83.6381,
						lng: -50.9766
					},
					southwest: {
						lat: 41.6765559,
						lng: -141.00187
					}
				}
			}
		}
	},
	{
		name: 'Australia',
		icon: '/pickleball-app/static/images/australia.svg',
		href: '/search',
		placeId: 'ChIJ38WHZwf9KysRUhNblaFnglM',
		place: {
			formatted_address: 'Australia',
			place_id: 'ChIJ38WHZwf9KysRUhNblaFnglM',
			geometry: {
				bounds: {
					northeast: {
						lat: -9.0880124,
						lng: 159.5150123
					},
					southwest: {
						lat: -55.3228176,
						lng: 112.6997261
					}
				},
				location: {
					lat: -25.274398,
					lng: 133.775136
				},
				viewport: {
					northeast: {
						lat: -9.0880124,
						lng: 159.5150123
					},
					southwest: {
						lat: -55.3228176,
						lng: 112.6997261
					}
				}
			}
		}
	},
	{
		name: 'Mexico',
		icon: '/pickleball-app/static/images/mexico-and-central-america.svg',
		href: '/search',
		placeId: 'ChIJU1NoiDs6BIQREZgJa760ZO0',
		place: {
			formatted_address: 'Mexico',
			place_id: 'ChIJU1NoiDs6BIQREZgJa760ZO0',
			geometry: {
				bounds: {
					northeast: {
						lat: 32.7187538,
						lng: -86.5887
					},
					southwest: {
						lat: 14.3895,
						lng: -118.6523001
					}
				},
				location: {
					lat: 23.634501,
					lng: -102.552784
				},
				viewport: {
					northeast: {
						lat: 32.7187538,
						lng: -86.5887
					},
					southwest: {
						lat: 14.3895,
						lng: -118.6523001
					}
				}
			}
		}
	},
	{
		name: 'South America',
		icon: '/pickleball-app/static/images/south-america.svg',
		href: '/search',
		placeId: 'ChIJtTRdNRw0CZQRK-PGyc8M1Gk',
		place: {
			formatted_address: 'South America',
			place_id: 'ChIJtTRdNRw0CZQRK-PGyc8M1Gk',
			geometry: {
				bounds: {
					northeast: {
						lat: 18.8384895,
						lng: -12.9824389
					},
					southwest: {
						lat: -58.36936679999999,
						lng: -111.0136956
					}
				},
				location: {
					lat: -8.783195,
					lng: -55.491477
				},
				viewport: {
					northeast: {
						lat: 18.8384895,
						lng: -12.9824389
					},
					southwest: {
						lat: -58.36936679999999,
						lng: -111.0136956
					}
				}
			}
		}
	},
	{
		name: 'Asia',
		icon: '/pickleball-app/static/images/asia.svg',
		href: '/search',
		placeId: 'ChIJV-jLJIrxYzYRWfSg0_xrQak',
		place: {
			formatted_address: 'Asia',
			place_id: 'ChIJV-jLJIrxYzYRWfSg0_xrQak',
			geometry: {
				bounds: {
					northeast: {
						lat: 56.988378621941436,
						lng: 134.70887102030264
					},
					southwest: {
						lat: -2.528312569819217,
						lng: 83.98394845667042
					}
				},
				location: {
					lat: 34.047863,
					lng: 100.6196553
				},
				viewport: {
					northeast: {
						lat: 56.988378621941436,
						lng: 134.70887102030264
					},
					southwest: {
						lat: -2.528312569819217,
						lng: 83.98394845667042
					}
				}
			}
		}
	},
	{
		name: 'Europe',
		icon: '/pickleball-app/static/images/europe.svg',
		href: '/search',
		placeId: 'ChIJhdqtz4aI7UYRefD8s-aZ73I',
		place: {
			formatted_address: 'Europe',
			place_id: 'ChIJhdqtz4aI7UYRefD8s-aZ73I',
			geometry: {
				bounds: {
					northeast: {
						lat: 68.35516402975539,
						lng: 36.444713183188014
					},
					southwest: {
						lat: 22.837528730295368,
						lng: -13.659909741717627
					}
				},
				location: {
					lat: 54.5259614,
					lng: 15.2551187
				},
				viewport: {
					northeast: {
						lat: 68.35516402975539,
						lng: 36.444713183188014
					},
					southwest: {
						lat: 22.837528730295368,
						lng: -13.659909741717627
					}
				}
			}
		}
	}
];
